/* Background and Base Styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(120deg, #1f1f1f, #333, #1C4171);
  background-size: 400% 400%;
  animation: gradientShift 15s ease infinite;
  color: #f0f0f0;
  padding-top: 0px;
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  margin-top: -20px;
}

/* Header */
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.header-logo {
  max-width: 200px;
  opacity: 0.9;
  transition: transform 0.3s ease;
  margin-bottom: 10px;
}

.header-logo:hover {
  transform: scale(1.05);
  opacity: 1;
}

/* Title and Description */
.App-title {
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: #f5f5f5;
  text-align: center;
  margin: 20px 0 10px;
  font-family: "Playfair Display";
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: calc(100vh - 200px);
  padding-top: 10px;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Main Media */
.main-media {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

/* Item Details */
.item-description {
  margin: 10px 0;
  font-family: "Playfair Display";
}

.item-type, .location-info, .item-description-text {
  margin-top: 20px;
  font-family: "Playfair Display";
}

.item-title-description-text {
  font-size: 1.2rem;
  font-family: "Playfair Display";
}

.label {
  font-weight: bold;
}

/* Carousel */
.carousel-container {
  margin: 0 auto;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  animation: slideIn 1s ease;
}

@keyframes slideIn {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

.carousel-image {
  width: 350px;
  height: 325px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-image:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 20px rgba(28, 65, 113, 0.5);
}

/* Spinner */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #1C4171;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Button */
.request-catalog-button {
  background-color: #1C4171;
  color: #fff;
  padding: 14px 28px;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.4s, transform 0.4s, box-shadow 0.4s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 0px;
}

.request-catalog-button:hover {
  background: linear-gradient(45deg, #1C4171, #0a284e);
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.request-catalog-button:active {
  box-shadow: 0 0 20px rgba(28, 65, 113, 0.8);
  transform: scale(0.95);
}

.already-requested{
  background-color: #1C4171;
  color: #fff;
  padding: 14px 28px;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  transition: background-color 0.4s, transform 0.4s, box-shadow 0.4s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 0px;
}

.request-consultation-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-dropdown {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.calendar-dropdown select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #1C4171;
  background-color: #f0f0f0;
  color: #333;
  font-size: 1rem;
  cursor: pointer;
}

.calendar-dropdown select:focus {
  outline: none;
  border: 1px solid #61dafb;
}

/* Modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto; 
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(50, 50, 50, 0.95);
  padding: 20px;
  border-radius: 10px;
  z-index: 1001;
  color: #f0f0f0;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.modal-image {
  max-width: 95%;
  max-height: 90%;
  width: auto;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6);
}

.modal-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.0);
  color: #1C4171;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 10;
}

.modal-nav-button:first-of-type {
  left: 10px;
}

.modal-nav-button:last-of-type {
  right: 10px;
}


.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.close-icon:hover {
  transform: scale(1.1);
}

.App-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 85%;
}

.form-row {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.form-row input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #888;
  background-color: transparent;
  color: #f5f5f5;
  transition: border-color 0.3s ease;
}

.form-row input:focus {
  border-bottom: 1px solid #61dafb;
}
/* Popup Styles */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background: linear-gradient(120deg, #1C4171, #2a5f9c);
  color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  text-align: center;
  padding: 20px 30px;
  animation: popupAppear 0.5s ease-out;
}

@keyframes popupAppear {
  0% {
    transform: translate(-50%, -60%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.popup-title {
  font-size: 1.8rem;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.popup-message {
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
  color: #e0e0e0;
  line-height: 1.6;
}

.popup button {
  background-color: #1C4171;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.popup button:hover {
  background-color: #2a5f9c;
  transform: scale(1.05);
}

.popup button:active {
  transform: scale(0.95);
}

.popup-close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  transition: transform 0.3s ease;
}

.popup-close-icon:hover {
  transform: scale(1.2);
}


.App-form button {
  background-color: #1C4171;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.4s, transform 0.4s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.App-form button:hover {
  background: linear-gradient(45deg, #1C4171, #0a284e);
  transform: scale(1.05);
}

/* Footer */
.footer {
  margin-top: 60px;
  width: 100%;
  text-align: center;
  padding: 30px 20px;
  color: #ddd;
  font-size: 14px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.footer:hover {
  color: #1C4171;
}

.footer-logo {
  width: 100px;
  vertical-align: middle;
  opacity: 0.8;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: scale(1.1);
  opacity: 1;
}

@media (max-width: 768px) {
  .header-logo { max-width: 150px; }
  .App-title { font-size: 1.8rem; }
}

@media (max-width: 480px) {
  .header-logo { max-width: 120px; }
  .App-title { font-size: 1.5rem; padding: 0 10px; }
  .center-image, .carousel-image { width: 100%; height: auto; }
  .request-catalog-button { font-size: 0.9rem; padding: 10px 20px; }
}
